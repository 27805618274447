import generalSettings from '@/config/general';
export default {
  saveResults(widgetId, results) {
    sessionStorage.setItem(`${generalSettings.productNameSpace}-widget-${widgetId}`, JSON.stringify(results));
    return results;
  },

  clearResults(widgetId) {
    sessionStorage.removeItem(`${generalSettings.productNameSpace}-widget-${widgetId}`);
  },

  findResults(widgetId) {
    const payload = sessionStorage.getItem(`${generalSettings.productNameSpace}-widget-${widgetId}`) || '[]';
    return JSON.parse(payload) || [];
  }

};