// import design from '@/config/design';
import { FilterBase, FilterValueType } from '@/utils/widget/createSelections/interfaces';
export default {
  styles: {
    borderTopWidth: '1px'
  }
};
export const selectionConfigDefault = {
  base: FilterBase.Series,
  valueType: FilterValueType.Numbers,
  seriesMinimumLength: 2
};
export const selectionConfigGroups = {
  valueType: FilterValueType.Numbers
};