import { useState, useEffect } from 'react';
export default function useStackedBarPlacement(_ref) {
  let {
    isVisible,
    amount,
    maximum,
    side,
    containerRef,
    containerWidth = 0,
    delay = 0
  } = _ref;
  const {
    0: isReady,
    1: setIsReady
  } = useState(false);
  const {
    0: textTransform,
    1: setTextTransform
  } = useState('');
  const {
    0: graphicTransform,
    1: setGraphicTransform
  } = useState('');
  useEffect(() => {
    isVisible && setTimeout(() => {
      const {
        graphicTranslateX,
        textTranslateX
      } = side ? calcMultiColTranslates(amount, maximum, side, containerRef) : calcSingleColTranslates(amount, maximum, containerRef);
      setGraphicTransform(`translateX(${graphicTranslateX})`);
      setTextTransform(`translateX(${textTranslateX})`);
      setIsReady(true);
    }, delay);
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [isVisible, containerWidth, amount, maximum, side]);
  return {
    textTransform,
    graphicTransform,
    isReady
  };
} // Position graphic bar and text
// proportionally to a maximum value

const calcMultiColTranslates = (amount, maximum, side, containerRef) => {
  var _containerRef$current;

  // Bar/graphic translate amount
  const graphicPercentOffset = Math.round(amount / maximum * 100);
  const graphicTranslateX = `${side === 'left' ? '-' : ''}${Math.max(2, graphicPercentOffset)}%`; // Text translate amount

  const amountPxOffset = Math.round((100 - graphicPercentOffset) * ((containerRef === null || containerRef === void 0 ? void 0 : (_containerRef$current = containerRef.current) === null || _containerRef$current === void 0 ? void 0 : _containerRef$current.offsetWidth) || 0)) / 100;
  const textTranslateX = `${side === 'left' ? '' : '-'}${amountPxOffset}px`;
  return {
    graphicTranslateX,
    textTranslateX
  };
};

const calcSingleColTranslates = (amount, maximum, containerRef) => {
  const percentOffset = Math.min(76.7, Math.round(amount / maximum * 100));
  const graphicTranslateX = `-${100 - Math.max(2, percentOffset)}%`;
  const containerEl = (containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) || {
    clientWidth: 0
  };
  const amountPxOffset = Math.round((100 - percentOffset) * containerEl.clientWidth) / 100;
  const textTranslateX = `-${amountPxOffset - 10}px`;
  return {
    graphicTranslateX,
    textTranslateX
  };
};