import { useState, useEffect } from 'react';
import globalEvents from '@/utils/globalEvents';
export default function useSubscribeValidation(questionId) {
  const {
    0: isValid,
    1: setIsValid
  } = useState(true);
  useEffect(() => {
    const unsubscribe = globalEvents.subscribe('questioninvalid', evt => {
      if (evt && evt.detail === questionId) {
        setIsValid(false);
      }
    });
    return unsubscribe; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const unsubscribe = globalEvents.subscribe('questionvalid', evt => {
      if (evt && evt.detail === questionId) {
        setIsValid(true);
      }
    });
    return unsubscribe; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    isValid
  };
}