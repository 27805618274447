export let FilterBase;

(function (FilterBase) {
  FilterBase["Pairs"] = "pairs";
  FilterBase["Series"] = "series";
})(FilterBase || (FilterBase = {}));

export let FilterValueType;

(function (FilterValueType) {
  FilterValueType["Truthy"] = "all";
  FilterValueType["Numbers"] = "numbers";
})(FilterValueType || (FilterValueType = {}));