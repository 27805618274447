import { useEffect } from 'react';
import globalEvents from '@/utils/globalEvents';
import { isValueApplicableToQuestion } from '@/utils/reporting/parseUserAnswer'; // interface Result {}

export default function useSubscribeEvents(question, formElement, setValue) {
  // Subscribe to value changes from
  // broadcasted event
  useEffect(() => {
    const unsubscribe = globalEvents.subscribe(`questionupdatevalue.${question.id}`, evt => {
      const srcValue = evt.detail;
      if (!formElement) return; // wait for render

      if (!isValueApplicableToQuestion(question, srcValue)) return; // invalid request

      setValue(srcValue); // Questions that are synced
      // from the `value` attirbute

      if (['money', 'duration'].includes(question.layout)) {
        return;
      } // Update other input types


      const input = formElement.querySelector(`[id="${question.id}"]`);
      input && setInputValue(input, srcValue);
    });
    return unsubscribe; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
} // Update the value/checked attribute
// of an input element

function setInputValue(input, value) {
  const inputType = input.getAttribute('type') || '';

  if (['checkbox', 'radio'].includes(inputType)) {
    // question.defaultChecked = Boolean(value);
    input.checked = value;
    return;
  }

  input.value = value;
}