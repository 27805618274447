import globalEvents from '@/utils/globalEvents';
export default function useBroadcastEvents(question) {
  const broadcastChange = value => {
    globalEvents.trigger(`questionchange.${question.id}`, {
      detail: value
    });
  };

  return {
    broadcastChange
  };
}