import { useCallback, useState, useMemo } from 'react';
import uuid from '@/utils/uuid';
export default function useSorting(_ref) {
  let {
    onSortChange = () => null,
    onSortStart = () => null,
    onSortEnd = () => null
  } = _ref;
  const {
    0: activeId,
    1: setActiveId
  } = useState(null);
  const {
    0: isSorting,
    1: setIsSorting
  } = useState(false);
  const sortableContextId = useMemo(() => uuid(), []);
  const handleDragStart = useCallback(event => {
    setIsSorting(true);
    onSortStart(event);
    setActiveId(event.active.id);
  }, [setIsSorting, onSortStart, setActiveId]);
  const handleDragEnd = useCallback(event => {
    var _over$data$current;

    const {
      active,
      over
    } = event;
    setIsSorting(false);
    if (!over || active.id === over.id) return;
    const srcIndex = active.data.current.srcIndex;
    const activeIndex = active.data.current.sortable.index;
    const overIndex = (_over$data$current = over.data.current) === null || _over$data$current === void 0 ? void 0 : _over$data$current.sortable.index;

    if (typeof activeIndex === 'number' && typeof overIndex === 'number') {
      var _active$data, _active$data$current, _over$data, _over$data$current2;

      const activeSortGroup = active === null || active === void 0 ? void 0 : (_active$data = active.data) === null || _active$data === void 0 ? void 0 : (_active$data$current = _active$data.current) === null || _active$data$current === void 0 ? void 0 : _active$data$current.sortGroup;
      const overSortGroup = over === null || over === void 0 ? void 0 : (_over$data = over.data) === null || _over$data === void 0 ? void 0 : (_over$data$current2 = _over$data.current) === null || _over$data$current2 === void 0 ? void 0 : _over$data$current2.sortGroup;
      onSortChange(activeIndex, overIndex, typeof srcIndex === 'number' ? srcIndex : undefined, {
        overSortGroup,
        activeSortGroup
      });
    }

    setActiveId(null);
    onSortEnd(event);
  }, [setIsSorting, onSortChange, onSortEnd, setActiveId]);
  return {
    activeId,
    sortableContextId,
    isSorting,
    handleDragStart,
    handleDragEnd
  };
}