// Get the normalized name of a value type
// assigned to a question
export default function getNormalizedValueType(valueType) {
  // Numbers
  if (['integer', 'floatingPoint', 'number'].includes(valueType)) {
    return 'integer';
  } // Booleans


  if (valueType === 'boolean') {
    return 'boolean';
  } // Default


  return 'string';
}