import debounce from './debounce';
const FUNC_ERROR_TEXT = 'Expected a function';

function throttle(func) {
  let wait = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  let leading = true;
  let trailing = true;

  if (typeof func != 'function') {
    throw new TypeError(FUNC_ERROR_TEXT);
  }

  if (isObject(options)) {
    leading = 'leading' in options ? !!options.leading : leading;
    trailing = 'trailing' in options ? !!options.trailing : trailing;
  }

  return debounce(func, wait, {
    leading: leading,
    maxWait: wait,
    trailing: trailing
  });
}

function isObject(value) {
  const type = typeof value;
  return value != null && (type === 'object' || type === 'function');
}

export default throttle;