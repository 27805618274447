export default function collectPairs(config) {
  const {
    selection,
    collectionIndex = -1,
    selectionFlatIndex = -1,
    width,
    height,
    tactic,
    labeled,
    valueMatrix,
    referenceMatrix
  } = config;
  const data = [];
  const size = valueMatrix.reduce((acc, row) => acc += row.length, 0); // Handle single cell selection

  if (size === 1) {
    data.push({
      value: getValueAt(0, 0),
      cell: getReferenceAt(0, 0)
    });
  } // Tactic #1 flat/tall:
  // - flat go left-right, right two until end
  // - tall go left-right, right two until end, down one row repeat


  if (size > 1 && (tactic === 'flat' || tactic === 'tall')) {
    for (let rowIndex = 0; rowIndex < height; rowIndex += 1) {
      for (let colIndex = 0; colIndex < width; colIndex += labeled ? 2 : 1) {
        const datum = {
          value: getValueAt(rowIndex, colIndex + (labeled ? 1 : 0)),
          cell: getReferenceAt(rowIndex, colIndex + (labeled ? 1 : 0))
        }; // Add label details

        if (labeled) {
          datum.label = getValueAt(rowIndex, colIndex);
          datum.labelCell = getReferenceAt(rowIndex, colIndex);
        }

        data.push(datum);
      }
    }
  } // Tactic #2 narrow/wide:
  // - narrow go up-down, down two until end
  // - wide go up-down, right one until end, down two


  if (size > 1 && (tactic === 'narrow' || tactic === 'wide')) {
    for (let rowIndex = 0; rowIndex < height; rowIndex += labeled ? 2 : 1) {
      for (let colIndex = 0; colIndex < width; colIndex += 1) {
        const datum = {
          value: getValueAt(rowIndex + (labeled ? 1 : 0), colIndex),
          cell: getReferenceAt(rowIndex + (labeled ? 1 : 0), colIndex)
        };

        if (labeled) {
          datum.label = getValueAt(rowIndex, colIndex);
          datum.labelCell = getReferenceAt(rowIndex, colIndex);
        }

        data.push(datum);
      }
    }
  } // Safe lookup of a reference at coordinates


  function getReferenceAt(rowIndex, colIndex) {
    return ((referenceMatrix[rowIndex] || [])[colIndex] || {}).ref || '';
  } // Safe lookup of a matrix value at coordinate


  function getValueAt(rowIndex, colIndex) {
    return (valueMatrix[rowIndex] || [])[colIndex];
  } // Collect pairs only creates a single
  // selection result per user selection group


  config.selectionResults.push({
    selections: selection,
    data,
    collectionIndex,
    selectionIndex: selectionFlatIndex
  });
  return config;
}