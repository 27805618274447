import cloneDeep from 'lodash/cloneDeep';
import { utils as utilsCore } from '@industriousapps/excelkits-core';
import { toOrdinal } from '@/utils/strings';
const {
  deordinalize
} = utilsCore; // Create an itterable list from
// the ordinal sections hash

export function ordinalSectionsToArray(sections) {
  const sectionIds = Object.keys(sections || {}); // Create an array of placeholders,
  // the same length as the number of sections

  const listPlaceholdersLength = Math.max(sectionIds.length - 1, 0);
  const list = [...Array(listPlaceholdersLength).keys()];
  return sectionIds.reduce((acc, sectionId) => {
    const ordinalIndex = deordinalize(sectionId);
    const section = sections[sectionId];
    const clonedSection = cloneDeep(section);
    clonedSection.id = sectionId; // add reference
    // Insert at ordinal index

    if (ordinalIndex !== -1) {
      acc.splice(ordinalIndex, 1, clonedSection);
    } else {
      // Fallback for non-ordinal/unknown keys
      // push to new index at end of the array
      acc.push(clonedSection);
    }

    return acc;
  }, list).filter(section => typeof section !== 'number'); // remove placeholders
} // Create an ordinal hash from an
// array of widget sections

export function arraySectionsToOrdinal(sections) {
  return sections.reduce((acc, section, index) => {
    const newId = toOrdinal(index);
    acc[newId] = cloneDeep(section);
    delete acc[newId].id; // sanity check

    return acc;
  }, {});
}