import { useMemo } from 'react';
import parseSelectOptions from '@/utils/reporting/parseSelectOptions';
import { utils as utilsCore } from '@industriousapps/excelkits-core';
import { isValidBoolean } from '@/utils/boolean';
import getNormalizedValueType from '@/utils/reporting/getNormalizedValueType';
import { triggerNativeEvent } from '@/utils/dom'; // const PREFIX =
//   'common: components: widget: Form: Question: useOptions:';

const {
  isValidNumber
} = utilsCore;
export default function useOptions(config) {
  const {
    srcOptions,
    question
  } = config;
  const {
    maximumValue: max,
    minimumValue: min
  } = question;
  const normalizedValueType = getNormalizedValueType(question.valueType);
  const isNumeric = normalizedValueType === 'integer';
  const isBoolean = normalizedValueType === 'boolean';
  const options = useMemo(() => {
    const parsedOptions = srcOptions ? parseSelectOptions(srcOptions) : []; // Accept all options with
    // numeric compatible values

    if (isNumeric) {
      // Filter options that are invalid
      // numbers and or are beyond the
      // min/max boundaries
      return parsedOptions.filter(_ref => {
        let {
          value
        } = _ref;
        return isValidNumber(value, false);
      }).filter(_ref2 => {
        let {
          value: srcValue
        } = _ref2;
        const value = parseFloat(srcValue);
        if (typeof min === 'number' && value < min) return false;
        if (typeof max === 'number' && value > max) return false;
        return true;
      });
    } // Accept all options with
    // boolean compatible values


    if (isBoolean) {
      return parsedOptions.filter(_ref3 => {
        let {
          value
        } = _ref3;
        return isValidBoolean(value);
      });
    } // Default/string options


    return parsedOptions;
  }, [srcOptions, isNumeric, isBoolean, min, max]); // Sync changes with a surrogate/alternative
  // User interface with a primary form field

  const onSurrogateChange = (refEl, value) => {
    const isHtmlElement = refEl instanceof HTMLElement;

    if (!refEl || !isHtmlElement && // @ts-ignore
    refEl.current instanceof HTMLElement === false) {
      // Return without broadcasting change
      // TODO: issue a console warning
      return;
    }

    const target = isHtmlElement ? refEl : refEl.current;
    triggerNativeEvent(target, {
      event: 'input',
      value: value
    });
  };

  return {
    options,
    onSurrogateChange
  };
}