import { isElementVisible, smoothScrollTo, getScrollParent } from '@/utils/dom'; // const PREFIX = 'utils: redirect-user:';

export default function redirectUserHandler(dest, targetScrollEl) {
  let scrollOnlyOutOfView = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

  if (!dest || false || typeof document === 'undefined') {
    return;
  }

  if (dest.search(/^#/) === -1) {
    // Redirect to separate page
    return window.location.replace(dest);
  } // Transition user to same-page results


  const targetEl = document.getElementById(dest.replace(/^#/, ''));
  const scrollingEl = targetScrollEl || getScrollParent(targetEl) || document.scrollingElement || document.documentElement;

  if (!targetEl) {
    // throw Error(`${PREFIX} same page scroll target not found ${dest}`);
    return;
  } // Prevent any scroll adjustments when
  // the target element is already in view


  if (scrollOnlyOutOfView && isElementVisible(targetEl)) {
    return;
  }

  const targetTop = targetEl.getBoundingClientRect().top + scrollingEl.scrollTop;
  const top = Math.max(targetTop, 0); // Account for navbar height

  smoothScrollTo({
    top
  }, scrollingEl);
}