export default function getDefaultValue(config) {
  const {
    srcDefaultValue,
    minimumValue,
    maximumValue,
    valueType
  } = config;
  let defaultValue = srcDefaultValue || (['number', 'integer'].includes(valueType) ? 0 : ''); // Ensure default value is never
  // below the minimum value

  if (typeof defaultValue === 'number' && typeof minimumValue === 'number' && defaultValue < minimumValue) {
    defaultValue = minimumValue;
  } // Ensure default value is never
  // above the maximum value


  if (typeof defaultValue === 'number' && typeof maximumValue === 'number' && defaultValue > maximumValue) {
    defaultValue = maximumValue;
  }

  return defaultValue;
}