import questionsSession from '@/services/session/questions';
import questionsLocalStorage from '@/services/localStorage/questions';
import parseUserAnswer from '@/utils/reporting/parseUserAnswer';
import reduceAnswers from './utils/reduceAnswers'; // const PREFIX = 'common: components: reporting: Form: useSaveChange:';

export default function useSaveChange(_ref) {
  let {
    questions,
    srcQuestions,
    saveSession,
    saveLocally
  } = _ref;

  // Save value to current session
  // or clear irrelevant record
  const sessionSave = (question, srcQuestion, srcValue) => {
    // Ignore invalid values
    if (!isValidUserAnswer(srcValue)) return;
    const value = parseUserAnswer(question, srcValue); // Remove inconsequential answer
    // otherwise save user result to session

    if (value === srcQuestion.defaultValue) {
      questionsSession.removeValue(question.id);
    } else {
      questionsSession.saveValue(question.id, value);
    }
  }; // Save value to local database
  // or clear irrelevant record


  function localDatabaseSave(question, srcQuestion, srcValue) {
    // Ignore invalid values
    if (!isValidUserAnswer(srcValue)) return;
    const value = parseUserAnswer(question, srcValue);

    if (value === srcQuestion.defaultValue) {
      questionsLocalStorage.removeValue(question.id);
    } else {
      questionsLocalStorage.saveValue(question.id, value);
    }
  } // Save the question's answer
  // to all the enabled stores


  function saveQuestionAnswer(question, value) {
    const srcQuestion = srcQuestions.find(_ref2 => {
      let {
        id
      } = _ref2;
      return id === question.id;
    });
    if (!srcQuestion) return; // Save to session

    saveSession && sessionSave(question, srcQuestion, value); // Save to local database

    saveLocally && localDatabaseSave(question, srcQuestion, value);
  } // Save all forms' answers
  // into all enabled stores


  function saveFormAnswers(formElements) {
    reduceAnswers({
      questions,
      formElements,
      reducer: (acc, question, userAnswer) => {
        saveQuestionAnswer(question, userAnswer);
        acc[question.id] = userAnswer;
        return acc;
      }
    });
  }

  return {
    saveQuestionAnswer,
    saveFormAnswers
  };
} // Check if user answer is valid
// to be persisted to any destination

function isValidUserAnswer(userAnswer) {
  if (userAnswer === null || userAnswer === undefined || userAnswer !== userAnswer // NaN check
  ) {
    return false;
  }

  return true;
}