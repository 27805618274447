import parseUserAnswer from '@/utils/reporting/parseUserAnswer';
import getFormValue from './getFormValue';
// Apply all discoverable form elements
// that map to questions to a reducer
export default function reduceAnswers(_ref) {
  let {
    questions = [],
    formElements,
    reducer
  } = _ref;
  return questions // Collect answers
  .reduce((acc, question) => {
    let formField = formElements ? formElements.namedItem(question.id) || // Attempt w/ question ID
    question.name && formElements.namedItem(question.name) // Attempt w/ question share name
    : null;
    if (!formField) return acc; // Lookup active/selected option in group
    // NOTE: checks are fix for jest

    if (window && typeof window.RadioNodeList !== 'undefined' && formField instanceof window.RadioNodeList) {
      formField = findCheckedField(formField);
    }

    if (formField && formField instanceof Element) {
      // Apply valid question and parsed user answer to reducer
      return reducer(acc, question, parseUserAnswer(question, getFormValue(formField)));
    }

    return acc;
  }, {});
} // Lookup any checked input in
// a group of radio fields

function findCheckedField(radioNodeList) {
  let checkedField = null;
  radioNodeList.forEach(element => {
    // @ts-ignore
    if (element.checked) {
      checkedField = element;
    }
  });
  return checkedField;
}