import { toOrdinal } from '@/utils/strings';
// Create a unique section element ID
// using either the section ID or its'
// index number
export default function getSectionElementId(_ref) {
  let {
    widgetId,
    sectionId,
    sectionIndex = 0
  } = _ref;
  const id = sectionId || toOrdinal(sectionIndex);
  return `${widgetId}-section-${id}`;
}