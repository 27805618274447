import { useState, useEffect, useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { utils as queryParamUtils } from '@/utils/queryParams';
import questionsSession from '@/services/session/questions';
import questionsLocalStorage from '@/services/localStorage/questions';
import { parseStoredAnswer, isValueApplicableToQuestion } from '@/utils/reporting/parseUserAnswer';
// const PREFIX =
//   'common: components: reporting: Form: useDefaultValueMergedQuestions:';
// Prevent lookup of certain layouts
// that are not applicable to the store
const EXCLUDED_LAYOUTS = {
  shareable: ['hidden'],
  saveSession: ['hidden'],
  saveLocally: ['hidden']
};
// Merge default values from other sources
// into the admin configured question's state
export default function useDefaultValueMergedQuestions(_ref) {
  let {
    sourceQuestions,
    localConfigQuestions,
    shareable,
    saveSession,
    saveLocally
  } = _ref;
  const {
    0: status,
    1: setStatus
  } = useState(sourceQuestions.length ? 'loading' : 'success');
  const {
    0: data,
    1: setData
  } = useState([]);
  const {
    0: qpLoaded,
    1: setQpLoaded
  } = useState(shareable ? false : true);
  const {
    0: localConfigLoaded,
    1: setLocalConfigLoaded
  } = useState(localConfigQuestions.length ? false : true);
  const {
    0: sessionLoaded,
    1: setSessionLoaded
  } = useState(saveSession ? false : true);
  const {
    0: localLoaded,
    1: setLocalLoaded
  } = useState(saveLocally ? false : true);
  const urlState = useMemo(() => ({}), []);
  const localConfigState = useMemo(() => ({}), []);
  const sessionState = useMemo(() => ({}), []);
  const localStorageState = useMemo(() => ({}), []); // Clone questions and lookup URL/session value

  useEffect(() => {
    setData(sourceQuestions.map(src => cloneDeep(src))); // 1st priority
    // Lookup answers from URL's query params

    if (shareable) {
      const qpHash = queryParamUtils.getStateHash();
      const qpQuestions = sourceQuestions.filter(_ref2 => {
        let {
          name,
          id
        } = _ref2;
        return qpHash[name || id];
      });
      !qpQuestions.length && setQpLoaded(true);
      qpQuestions.filter(question => !EXCLUDED_LAYOUTS.shareable.includes(question.layout)).forEach((question, index, arr) => {
        const {
          id,
          name
        } = question;
        const value = parseStoredAnswer(question, qpHash[name || id]);
        if (value !== undefined) urlState[id] = value;
        if (isLast(index, arr)) setQpLoaded(true);
      });
    } // 2nd priority
    // Apply local config values


    !localConfigQuestions.length && setLocalConfigLoaded(true);
    localConfigQuestions.forEach((localQuestion, index, arr) => {
      const {
        id,
        defaultValue
      } = localQuestion;
      const question = sourceQuestions.find(_ref3 => {
        let {
          id: questionId
        } = _ref3;
        return questionId === id;
      });
      const value = question ? defaultValue : undefined;
      if (value !== undefined) localConfigState[id] = value;
      if (isLast(index, arr)) setLocalConfigLoaded(true);
    }); // 3rd priority
    // Lookup user's session values

    if (saveSession) {
      !sourceQuestions.length && setSessionLoaded(true);
      sourceQuestions.filter(question => !EXCLUDED_LAYOUTS.saveSession.includes(question.layout)).forEach((question, index, arr) => {
        const value = questionsSession.findValue(question);
        if (value !== undefined) sessionState[question.id] = value;
        if (isLast(index, arr)) setSessionLoaded(true);
      });
    } // 4th priority
    // Lookup user's local storage values


    if (saveLocally) {
      !sourceQuestions.length && setLocalLoaded(true);
      sourceQuestions.filter(question => !EXCLUDED_LAYOUTS.saveLocally.includes(question.layout)).forEach((question, index, arr) => {
        const value = questionsLocalStorage.findValue(question);
        if (value !== undefined) localStorageState[question.id] = value;
        if (isLast(index, arr)) setLocalLoaded(true);
      });
    }
  }, [sourceQuestions, localConfigQuestions, urlState, localConfigState, sessionState, localStorageState, shareable, saveSession, saveLocally]); // Merge any default values from other sources into
  // existing questions so configured defaults have
  // lowest priority

  useEffect(() => {
    // Ensure stores have finished loading answers
    if (!qpLoaded || !localConfigLoaded || !sessionLoaded || !localLoaded) return;
    data.forEach((question, index, arr) => {
      let value;

      if (urlState[question.id]) {
        // URL is highest-priority
        value = urlState[question.id];
      } else if (localConfigState[question.id]) {
        // Local config is upper-medium-priority
        value = localConfigState[question.id];
      } else if (sessionState[question.id]) {
        // Session storage is lower-medium-priority
        value = sessionState[question.id];
      } else if (localStorageState[question.id]) {
        // Local storage is low-priority answer
        value = localStorageState[question.id];
      } // Lowest priority is question.defaultValue
      // Ensure that discovered default value
      // is applicable to the current question
      // state before setting it as the default


      if (isValueApplicableToQuestion(question, value)) {
        setQuestionDefault(question, value);
      } // Mark questions as ready to load


      if (isLast(index, arr)) setStatus('success');
    });
  }, [data, urlState, localConfigState, sessionState, localStorageState, qpLoaded, localConfigLoaded, sessionLoaded, localLoaded]);
  return {
    status,
    data
  };
} // Set default checked/value depending
// on the questions layout

function setQuestionDefault(question, value) {
  if (question.layout === 'checkbox') {
    question.defaultChecked = Boolean(value);
  }

  question.defaultValue = value;
} // Is index the last in an array


function isLast(index, arr) {
  return index === arr.length - 1;
}