import { useState, useEffect } from 'react';
import logger from '@/utils/logger';
import { isValueApplicableToQuestion } from '@/utils/reporting/parseUserAnswer';
export default function useLocalConfig(_ref) {
  let {
    localConfigId,
    sourceQuestions: questions
  } = _ref;
  const {
    0: localConfigQuestions,
    1: setLocalConfigQuestions
  } = useState([]);
  useEffect(() => {
    if (!localConfigId || typeof document === 'undefined') return;
    const result = [];
    const localConfig = document.getElementById(localConfigId);
    const tagName = localConfig ? `${localConfig.tagName}`.toLowerCase() : '';

    if (tagName !== 'script') {
      logger.warn(`script with id="${localConfigId}" could not be found`);
      return;
    }

    let config = {};

    try {
      config = JSON.parse((localConfig === null || localConfig === void 0 ? void 0 : localConfig.textContent) || '');
    } catch (err) {
      logger.warn(`script with id="${localConfigId}" contains unparsable JSON`);
      return;
    } // Add question default values to results


    if (Array.isArray(config.questions)) {
      const validLocalQuestions = config.questions.filter((_ref2, index) => {
        let {
          id,
          defaultValue
        } = _ref2;
        const question = questions.find(_ref3 => {
          let {
            id: questionId
          } = _ref3;
          return questionId === `${id}`;
        }); // Log non-existent question id's

        if (!question) {
          logger.warn(`local config question id="${id || 'unknown'}" at index ${index} has no associated question`);
          return false;
        }

        const isApplicable = isValueApplicableToQuestion(question, defaultValue); // Log invalid question default value

        if (!isApplicable) {
          logger.warn(`local config question id="${id}" has a non-applicable default value`);
          return false;
        }

        return true;
      }); // Add all valid local config questions to results

      validLocalQuestions.forEach(question => {
        result.push({
          id: question.id,
          defaultValue: question.defaultValue
        });
      });
      setLocalConfigQuestions([...result]);
    }
  }, [localConfigId, questions]);
  return {
    questions: localConfigQuestions
  };
}