import generalSettings from '@/config/general';
import { parseStoredAnswer } from '@/utils/reporting/parseUserAnswer';
const VERSION = '0';
export default {
  saveValue(questionId, value) {
    localStorage.setItem(createKey(questionId), `${value}`);
    return value;
  },

  findValue(question) {
    const payload = localStorage.getItem(createKey(question.id));
    if (payload === null) return undefined;
    return parseStoredAnswer(question, payload);
  },

  removeValue(questionId) {
    localStorage.removeItem(createKey(questionId));
  }

};

function createKey(questionId) {
  return `${generalSettings.productNameSpace}-${VERSION}-${questionId}`;
}