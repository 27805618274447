export default function collectSeries(config) {
  const {
    width,
    height,
    tactic,
    labeled,
    valueMatrix,
    collectionIndex = -1,
    selectionFlatIndex = -1,
    referenceMatrix,
    seriesMinimumLength // TODO implement as way to group selections
    // Use-case overview visualization with max 3 items / item
    // seriesMaximumLength

  } = config;
  const selectionGroups = [];
  const size = valueMatrix.reduce((acc, row) => acc += row.length, 0); // Handle single cell selection

  if (size === 1) {
    const resultRow = createSelectionGroup(collectionIndex, selectionFlatIndex);
    resultRow.selections = getReferenceAt(0, 0);
    resultRow.data.push({
      value: getValueAt(0, 0),
      cell: getReferenceAt(0, 0)
    });
    selectionGroups.push(resultRow);
  } // Tactic #1 flat/wide:
  // - Go left-right, right until end, down one row repeat
  // - Create 1 selection group for each row


  if (size > 1 && (tactic === 'flat' || tactic === 'wide')) {
    for (let rowIndex = 0; rowIndex < height; rowIndex += 1) {
      const resultRow = createSelectionGroup(collectionIndex, selectionFlatIndex);
      resultRow.selections = getReferenceAt(rowIndex, 0);

      for (let colIndex = 0; colIndex < width; colIndex += 1) {
        if (labeled && colIndex === 0 && isLargeEnoughToLabel(width - colIndex, seriesMinimumLength)) {
          // Add 1st column label & 2nd column value
          resultRow.data.push({
            label: getValueAt(rowIndex, colIndex),
            labelCell: getReferenceAt(rowIndex, colIndex),
            value: getValueAt(rowIndex, colIndex + 1),
            cell: getReferenceAt(rowIndex, colIndex + 1)
          });
          colIndex += 1; // skip #2 columan already added
        } else {
          // Add value only data
          resultRow.data.push({
            value: getValueAt(rowIndex, colIndex),
            cell: getReferenceAt(rowIndex, colIndex)
          });
        }
      } // Complete selection for series


      resultRow.selections += `:${(resultRow.data[resultRow.data.length - 1] || {}).cell || ''}`; // Add selection group for row to collection

      selectionGroups.push(resultRow);
    }
  } // Tactic #2 narrow/tall:
  // - Go up-down, down until end, right one column repeat
  // - Create 1 selection group for each column


  if (size > 1 && (tactic === 'narrow' || tactic === 'tall')) {
    for (let colIndex = 0; colIndex < width; colIndex += 1) {
      const resultColumn = createSelectionGroup(collectionIndex, selectionFlatIndex);
      resultColumn.selections = getReferenceAt(0, colIndex);

      for (let rowIndex = 0; rowIndex < height; rowIndex += 1) {
        if (labeled && rowIndex === 0 && isLargeEnoughToLabel(height - rowIndex, seriesMinimumLength)) {
          // Add 1st row label & 2nd row value
          resultColumn.data.push({
            label: getValueAt(rowIndex, colIndex),
            labelCell: getReferenceAt(rowIndex, colIndex),
            value: getValueAt(rowIndex + 1, colIndex),
            cell: getReferenceAt(rowIndex + 1, colIndex)
          });
          rowIndex += 1; // skip #2 row already added
        } else {
          // Add value only data
          resultColumn.data.push({
            value: getValueAt(rowIndex, colIndex),
            cell: getReferenceAt(rowIndex, colIndex)
          });
        }
      } // Complete selection for series


      resultColumn.selections += `:${(resultColumn.data[resultColumn.data.length - 1] || {}).cell || ''}`; // Add selection group for row to collection

      selectionGroups.push(resultColumn);
    }
  } // Append all complete series selection groups


  config.selectionResults.push(...selectionGroups); // Safe lookup of a reference at coordinates

  function getReferenceAt(rowIndex, colIndex) {
    return ((referenceMatrix[rowIndex] || [])[colIndex] || {}).ref || '';
  } // Safe lookup of a matrix value at coordinate


  function getValueAt(rowIndex, colIndex) {
    return (valueMatrix[rowIndex] || [])[colIndex];
  }

  return config;
} // Create an empty selection group

function createSelectionGroup(colIndex, index) {
  return {
    selections: '',
    data: [],
    collectionIndex: colIndex,
    // index of collection selection is a member of
    selectionIndex: index // flattened index of selection within all selections

  };
} // Check if there is enough values in height
// to accomodate the minimum series value


function isLargeEnoughToLabel(height) {
  let seriesMinimumLength = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : -1;
  return height > seriesMinimumLength;
}