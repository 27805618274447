import { useEffect, useRef } from 'react';
import * as Sentry from '@sentry/nextjs';
import widgetsApi from '@/services/api/widgets';
import environment from '@/config/environment';
import { createWrappedError } from '@/utils/errors';
const PREFIX = 'common: hooks: useTrackWidgetView:';
// Track unique views of a widget in production
export default function useTrackWidgetView(_ref) {
  let {
    widgetId,
    editing
  } = _ref;
  const isViewTracked = useRef(false);
  useEffect(() => {
    if (!widgetId || isViewTracked.current !== false || isHostMatching(environment.baseUrl) || environment.isDevelopment || editing) return;
    isViewTracked.current = true;

    const trackView = () => {
      widgetsApi.getWidgetStats(widgetId, ['v']).catch(err => {
        Sentry.captureException(createWrappedError(`${PREFIX} request failed with code "${err.code}"`, err));
      });
    };

    if (document.readyState === 'loading') {
      // Wait for the document to be ready before tracking the view
      return document.addEventListener('DOMContentLoaded', () => {
        trackView();
      }, {
        once: true
      });
    } else {
      trackView();
    }
  }, [widgetId, editing]);
}

const isHostMatching = url => {
  const host = new URL(url).host;
  return host === window.location.host;
};