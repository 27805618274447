import { marked } from 'marked';
import { useMemo } from 'react';
import flatten from 'lodash/flatten';
import templateEngine from '@/utils/templateEngine';
import { selectionResultsToHash, seriesOptionsToHash } from '@/utils/widget/hashing';
export default function useListData(results, seriesOptions) {
  const selectionGroups = results ? results[0] : [];
  const resultsHash = selectionResultsToHash(results);
  const optionsHash = seriesOptionsToHash(seriesOptions);
  return useMemo(() => {
    if (!selectionGroups || selectionGroups.length === 0) {
      return {
        data: []
      };
    }

    const dataGroups = selectionGroups // Pair selection group with series options
    .map(selectionResultGroup => {
      const options = seriesOptions[selectionResultGroup.selectionIndex];
      return {
        group: selectionResultGroup,
        options
      };
    }) // Filter collapsed
    .filter(_ref => {
      let {
        group,
        options = {}
      } = _ref;

      // Filter out collapsed group
      // when all its' values are not truthy
      if (options.collapsible) {
        return group.data.some(item => Boolean(item.value));
      }

      return true;
    }) // Create series data
    .map(_ref2 => {
      let {
        group,
        options = {}
      } = _ref2;
      const userLabels = options.labels || {}; // Provide default template

      const template = options.template || `{{value}}`; // Sparate rows by default

      const separator = typeof options.separator === 'boolean' ? options.separator : true; // Convert each item in group
      // into a renderable item for
      // the visualization

      return group.data.map((item, index) => ({
        separator,
        label: marked.parseInline(`${userLabels[item.labelCell || ''] || ( // Check customized label cells
        index === 0 ? options.label : '') || // Check singular label for first item
        item.label || // Check spreadsheet label value
        ''}`),
        value: item.value,
        text: marked.parseInline(templateEngine(template, {
          value: item.value
        })),
        color: options.color || '',
        selection: group.selections,
        selectionIndex: group.selectionIndex
      }));
    });
    return {
      data: flatten(dataGroups)
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsHash, optionsHash, selectionGroups]);
}