import { useState, useEffect, useCallback } from 'react';
import globalEvents from '@/utils/globalEvents';
import widgetSessionStorage from '@/services/session/widgets';
export default function useSubscribeWidgetResults(reportingWidgetId) {
  const {
    0: results,
    1: setReports
  } = useState([]); // Query for requested reports

  const loadResults = useCallback(() => {
    const results = widgetSessionStorage.findResults(reportingWidgetId) || [];
    const update = results.filter(Boolean);
    setReports(update);
  }, [reportingWidgetId]); // Update results on form submissions

  useEffect(() => {
    const unsubscribe = globalEvents.subscribe('formsubmit', evt => {
      if (!evt || evt.detail !== reportingWidgetId) {
        return; // ignore unrelated submissions
      }

      loadResults();
    });
    return unsubscribe; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // Request results on load
    requestAnimationFrame(loadResults);
  }, [loadResults]);
  return results;
}