import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

export const MIN_PADDING = 0;
export const MAX_PADDING = 9999; // Set padding value to null if it is not a valid number

export const getDefaultPadding = padding => {
  return padding ? Object.entries(padding).reduce((acc, _ref) => {
    let [key, value] = _ref;
    const parsedValue = parseInt(value, 10);
    return _objectSpread(_objectSpread({}, acc), {}, {
      [key]: isNaN(parsedValue) ? null : parsedValue
    });
  }, {}) : getEmptyPadding();
};
export const getEmptyPadding = () => {
  return {
    paddingTop: null,
    paddingRight: null,
    paddingBottom: null,
    paddingLeft: null
  };
}; // Convert padding to css compatible values in px

export const getCssPadding = padding => {
  return Object.entries(padding).reduce((acc, _ref2) => {
    let [key, value] = _ref2;
    return _objectSpread(_objectSpread({}, acc), {}, {
      [key]: isNaN(parseInt(value, 10)) ? null : `${value}px`
    });
  }, {});
};