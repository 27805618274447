import { utils as utilsCore } from '@industriousapps/excelkits-core';
import { isValidDuration, durationStringToNumber, durationNumberToString } from '@/utils/time';
import getNormalizedValueType from '@/utils/reporting/getNormalizedValueType';
const {
  isValidNumber,
  toNumericString,
  toFloatingPoint
} = utilsCore;
export default ((question, userAnswer) => {
  const {
    layout,
    valueType: srcValueType,
    defaultValue
  } = question;
  const valueType = getNormalizedValueType(srcValueType);

  if (layout === 'money') {
    const answer = `${isValidNumber(userAnswer) ? userAnswer : defaultValue}`.trim();
    return toFloatingPoint(answer); // number
  } else if (layout === 'checkbox') {
    // Default to boolean if undefined
    const checkedValue = typeof question.checkedValue !== 'undefined' ? question.checkedValue : true;
    const uncheckedValue = typeof question.uncheckedValue !== 'undefined' ? question.uncheckedValue : false; // @ts-ignore

    return userAnswer ? checkedValue : uncheckedValue;
  } else if (layout === 'duration' && typeof userAnswer === 'string') {
    const answer = `${isValidDuration(userAnswer) ? userAnswer : ''}`.trim();
    const fallback = durationNumberToString(parseFloat(`${defaultValue}`) || 0);
    return durationStringToNumber(`${answer || fallback}`) || 0;
  } else if ('integer' === valueType) {
    const answer = `${isValidNumber(userAnswer) ? userAnswer : defaultValue}`.trim();
    return parseFloat(toNumericString(answer)) || 0; // number
  } else if (valueType === 'boolean') {
    const validBool = `${userAnswer}`.trim().search(/true|false/i) > -1;
    const answer = `${validBool ? userAnswer : defaultValue}`.trim();
    return answer.toLowerCase() === 'true' ? true : false; // boolean
  } else {
    return `${userAnswer || defaultValue || ''}`.trim(); // string
  }
}); // Convert a stored string answer into
// a value applicable to the question type
// or return undefined if unparsable for type

export function parseStoredAnswer(question, value) {
  const {
    layout,
    valueType: srcValueType
  } = question;
  const valueType = getNormalizedValueType(srcValueType);

  if (layout === 'money') {
    const parsed = toFloatingPoint(value); // number

    return parsed === parsed ? parsed : undefined; // NaN check
  } else if (layout === 'checkbox' || layout === 'radio') {
    // Default to boolean if undefined
    const checkedValue = typeof question.checkedValue !== 'undefined' ? question.checkedValue : true;
    const uncheckedValue = typeof question.uncheckedValue !== 'undefined' ? question.uncheckedValue : false;
    const testValue = value.toLowerCase();
    return testValue === 'true' ? checkedValue : testValue === 'false' ? uncheckedValue : undefined;
  } else if (layout === 'duration' && isValidDuration(value)) {
    const parsed = durationStringToNumber(value);
    return parsed === parsed ? parsed : undefined; // NaN check
  } else if ('integer' === valueType) {
    const parsed = parseFloat(toNumericString(value)); // number

    return parsed === parsed ? parsed : undefined; // NaN check
  } else if (valueType === 'boolean') {
    const testValue = value.toLowerCase();
    return testValue === 'true' ? true : testValue === 'false' ? false : undefined;
  }

  return `${value || ''}`.trim(); // string
} // Convert a question value into a sharable
// URL value that can be easily read

export function toUrlValue(question, value) {
  const {
    layout
  } = question;

  if (layout === 'duration') {
    return durationNumberToString(Number(value || 0));
  }

  return value;
} // Checks if a value is applicable
// to a question as its' default

export function isValueApplicableToQuestion(question, newValue) {
  const {
    layout,
    valueType: srcValueType
  } = question;
  const typeOfNewValue = typeof newValue;
  if (typeOfNewValue === 'undefined') return false;
  const valueType = getNormalizedValueType(srcValueType);

  if (layout === 'money' || valueType === 'integer') {
    return typeOfNewValue === 'number'; // must be number
  } else if (layout === 'checkbox') {
    // Default to boolean if undefined
    const checkedValue = typeof question.checkedValue !== 'undefined' ? question.checkedValue : true;
    const uncheckedValue = typeof question.uncheckedValue !== 'undefined' ? question.uncheckedValue : false;
    return newValue === checkedValue || newValue === uncheckedValue;
  } else if (valueType === 'boolean') {
    return typeOfNewValue === 'boolean';
  } else if (valueType === 'string') {
    return typeOfNewValue === 'string';
  }

  return false;
}