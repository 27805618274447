import design, { remCalc } from '@/config/design';
export default {
  styles: {
    gridMaxColumns: '1',
    paddingTop: '0',
    paddingRight: '0',
    paddingBottom: '0',
    paddingLeft: '0'
  },
  stylesMediumUp: {},
  stylesMedium: {},
  stylesLarge: {
    layoutPaddingTop: design.globals.xxlgPadding,
    gridRowGap: design.globals.xlgPadding
  },
  stylesMainSections: {},
  stylesMainSectionsMediumUp: {
    paddingRight: design.globals.lgPadding
  },
  stylesMainSectionsLarge: {
    paddingLeft: remCalc(35),
    paddingRight: remCalc(55)
  },
  stylesSidebarSections: {},
  stylesSidebarSectionsMediumUp: {
    paddingLeft: design.globals.lgPadding
  },
  stylesSidebarSectionsLarge: {
    paddingTop: design.globals.lgPadding,
    paddingRight: design.globals.lgPadding,
    paddingBottom: design.globals.lgPadding
  }
};