import flattenDeep from 'lodash/flattenDeep';
import parseSelections, { findAndReplaceSelections } from '@/utils/widget/parseSelections';
import { FilterBase, FilterValueType } from './interfaces';
import findSpreadsheetSelection from './findSpreadsheetSelection';
import { deepArrayMap } from '@/utils/array';
export { FilterBase, FilterValueType };
export default function createSelectionsV2(_ref) {
  let {
    result,
    selections,
    selectionConfig,
    labeled = false
  } = _ref;
  let selectionCollection;

  if (typeof selections === 'string') {
    selectionCollection = parseSelections(selections);
  } else {
    selectionCollection = selections;
  } // Return early


  if (!selectionCollection.length) {
    return [];
  } // Setup configuration for unique section type


  const base = selectionConfig.base || FilterBase.Pairs;
  const valueType = selectionConfig.valueType || FilterValueType.Truthy;
  const confSeriesMinLength = typeof selectionConfig.seriesMinimumLength === 'number' ? selectionConfig.seriesMinimumLength : Infinity;
  const seriesMinimumLength = typeof confSeriesMinLength === 'number' ? confSeriesMinLength : 1;
  const flatSelections = flattenDeep(selectionCollection); // @ts-ignore

  const flatCollectionIndexes = selectionCollection.reduce((acc, selections, collectionIndex) => {
    deepArrayMap(selections, () => {
      acc.push(collectionIndex);
    });
    return acc;
  }, []);
  const flatResults = flattenDeep(flatSelections.map((selection, index) => {
    const colIndex = flatCollectionIndexes.shift();
    return findSpreadsheetSelection({
      selection,
      collectionIndex: typeof colIndex === 'number' ? colIndex : -1,
      selectionFlatIndex: index,
      labeled,
      workbook: result.data,
      base,
      valueType,
      seriesMinimumLength
    });
  }));

  if (!flatResults.length) {
    return [];
  } // Replace selections with results


  return findAndReplaceSelections(selectionCollection, flatResults);
}