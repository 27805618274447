import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

// Get the gradient object from the gradient string.
// Based on the "react-best-gradient-color-picker" implementation
// of the "useColorPicker" hook.
// Source:
// https://github.com/hxf31891/react-gradient-color-picker/blob/main/src/hooks/useColorPicker.ts
import { toRGBA, transparentize } from './colors';
import { isUpperCase } from './strings';
// Parses a gradient string and returns
// an object that represents the gradient
// along with the color stops.
export const getGradientObject = value => {
  const colors = getColors(value);
  const {
    degrees,
    degreeStr,
    isGradient,
    gradientType
  } = getDetails(value);

  if (isGradient) {
    return {
      isGradient: true,
      gradientType: gradientType,
      degreeStr: degreeStr,
      degrees: degrees,
      colors: colors === null || colors === void 0 ? void 0 : colors.map(c => {
        var _c$value;

        return _objectSpread(_objectSpread({}, c), {}, {
          value: (_c$value = c.value) === null || _c$value === void 0 ? void 0 : _c$value.toLowerCase()
        });
      })
    };
  } else {
    return {
      isGradient: false,
      gradientType: null,
      degrees: null,
      degreeStr: null,
      colors: colors === null || colors === void 0 ? void 0 : colors.map(c => {
        var _c$value2;

        return _objectSpread(_objectSpread({}, c), {}, {
          value: (_c$value2 = c.value) === null || _c$value2 === void 0 ? void 0 : _c$value2.toLowerCase()
        });
      })
    };
  }
}; // Extracts color stops from the gradient string

const getColors = value => {
  const isGradient = value === null || value === void 0 ? void 0 : value.includes('gradient');

  if (isGradient) {
    const isConic = value === null || value === void 0 ? void 0 : value.includes('conic');
    const safeValue = !isConic ? value : '';

    if (isConic) {
      console.log('Sorry we cant handle conic gradients yet');
    }

    const obj = gradientParser(safeValue);
    return obj === null || obj === void 0 ? void 0 : obj.colorStops;
  } else {
    const safeValue = value || '';
    return [{
      value: safeValue
    }];
  }
}; // Converts a direction string to degrees


const convertShortHandDeg = dir => {
  if (dir === 'to top') {
    return 0;
  } else if (dir === 'to bottom') {
    return 180;
  } else if (dir === 'to left') {
    return 270;
  } else if (dir === 'to right') {
    return 90;
  } else if (dir === 'to top right') {
    return 45;
  } else if (dir === 'to bottom right') {
    return 135;
  } else if (dir === 'to bottom left') {
    return 225;
  } else if (dir === 'to top left') {
    return 315;
  } else {
    const safeDir = dir || '0';
    return parseInt(safeDir);
  }
}; // Extracts the degrees from a gradient string


const getDegrees = value => {
  var _s1$split$;

  const s1 = value === null || value === void 0 ? void 0 : value.split(',')[0];
  const s2 = s1 === null || s1 === void 0 ? void 0 : (_s1$split$ = s1.split('(')[1]) === null || _s1$split$ === void 0 ? void 0 : _s1$split$.replace('deg', '');
  return convertShortHandDeg(s2);
}; // Extracts details from the gradient string


const getDetails = value => {
  const isGradient = value === null || value === void 0 ? void 0 : value.includes('gradient');
  const gradientType = value === null || value === void 0 ? void 0 : value.split('(')[0];
  const degrees = getDegrees(value);
  const degreeStr = gradientType === 'linear-gradient' ? `${degrees}deg` : 'circle';
  return {
    degrees,
    degreeStr,
    isGradient,
    gradientType
  };
};

const gradientParser = function () {
  let input = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  // Define tokens for parsing
  const tokens = {
    linearGradient: /^(-(webkit|o|ms|moz)-)?(linear-gradient)/i,
    repeatingLinearGradient: /^(-(webkit|o|ms|moz)-)?(repeating-linear-gradient)/i,
    radialGradient: /^(-(webkit|o|ms|moz)-)?(radial-gradient)/i,
    repeatingRadialGradient: /^(-(webkit|o|ms|moz)-)?(repeating-radial-gradient)/i,
    sideOrCorner: /^to (left (top|bottom)|right (top|bottom)|top (left|right)|bottom (left|right)|left|right|top|bottom)/i,
    extentKeywords: /^(closest-side|closest-corner|farthest-side|farthest-corner|contain|cover)/,
    positionKeywords: /^(left|center|right|top|bottom)/i,
    pixelValue: /^(-?(([0-9]*\.[0-9]+)|([0-9]+\.?)))px/,
    percentageValue: /^(-?(([0-9]*\.[0-9]+)|([0-9]+\.?)))%/,
    emValue: /^(-?(([0-9]*\.[0-9]+)|([0-9]+\.?)))em/,
    angleValue: /^(-?(([0-9]*\.[0-9]+)|([0-9]+\.?)))deg/,
    startCall: /^\(/,
    endCall: /^\)/,
    comma: /^,/,
    hexColor: /^#([0-9a-fA-F]+)/,
    rgbColor: /^rgb/i,
    spacedRgbColor: /^(\d{1,3})\s+(\d{1,3})\s+(\d{1,3})\s+\/\s+([0-1](\.\d+)?)/,
    rgbaColor: /^rgba/i,
    number: /^(([0-9]*\.[0-9]+)|([0-9]+\.?))/
  };

  function error(msg) {
    const err = new Error(input + ': ' + msg);
    throw err;
  } // Consumes a portion of the input string


  function consume(size) {
    input = input.substr(size);
  } // Scans the input for a matching pattern


  function scan(regexp) {
    const blankCaptures = /^[\n\r\t\s]+/.exec(input);

    if (blankCaptures) {
      consume(blankCaptures[0].length);
    }

    const captures = regexp.exec(input);

    if (captures) {
      consume(captures[0].length);
    }

    return captures;
  } // Matches a listing of items separated by commas


  function matchListing(matcher) {
    let captures = matcher();
    const result = [];

    if (captures) {
      result.push(captures);

      while (scan(tokens.comma)) {
        captures = matcher();

        if (captures) {
          result.push(captures);
        } else {
          error('One extra comma');
        }
      }
    }

    return result;
  } // Matches a pattern and returns an object with type and value


  function match(type, pattern, captureIndex) {
    const captures = scan(pattern);

    if (captures) {
      return {
        type: type,
        value: captures[captureIndex]
      };
    }
  } // Matches a hexadecimal color


  function matchHexColor() {
    const hexObj = match('hex', tokens.hexColor, 1);

    if (hexObj !== null && hexObj !== void 0 && hexObj.value) {
      const {
        r,
        g,
        b,
        a
      } = toRGBA(hexObj === null || hexObj === void 0 ? void 0 : hexObj.value);
      return {
        value: `rgba(${r}, ${g}, ${b}, ${a})`
      };
    }
  } // Checks and formats RGBA values


  const checkCaps = val => {
    const capIt = isUpperCase(val === null || val === void 0 ? void 0 : val[0]);
    return {
      value: `${capIt ? 'RGBA' : 'rgba'}(${matchListing(matchNumber)})`
    };
  }; // Matches a function call pattern and processes it with a callback


  function matchCall(pattern, callback) {
    const captures = scan(pattern);

    if (captures) {
      if (!scan(tokens.startCall)) {
        error('Missing (');
      }

      const result = callback(captures);

      if (!scan(tokens.endCall)) {
        error('Missing )');
      }

      return result;
    }
  } // Matches an RGBA color


  function matchRGBAColor() {
    return matchCall(tokens.rgbaColor, checkCaps);
  } // Matches an RGB color


  function matchRGBColor() {
    return matchCall(tokens.rgbColor, convertRgb);
  } // Matches any color format


  function matchColor() {
    return matchHexColor() || matchRGBAColor() || matchRGBColor();
  } // Matches a color stop in a gradient


  function matchColorStop() {
    var _matchDistance;

    const color = matchColor();

    if (!color) {
      error('Expected color definition');
      return;
    }

    color.left = parseInt(((_matchDistance = matchDistance()) === null || _matchDistance === void 0 ? void 0 : _matchDistance.value) || '0');
    return color;
  } // Matches a gradient definition


  function matchGradient(gradientType, pattern, orientationMatcher) {
    return matchCall(pattern, function () {
      const orientation = orientationMatcher();

      if (orientation) {
        if (!scan(tokens.comma)) {
          error('Missing comma before color stops');
        }
      }

      return {
        type: gradientType,
        orientation: orientation,
        colorStops: matchListing(matchColorStop)
      };
    });
  } // Matches the orientation of a linear gradient


  function matchLinearOrientation() {
    return matchSideOrCorner() || matchAngle();
  } // Matches a gradient definition


  function matchDefinition() {
    return matchGradient('linear-gradient', tokens.linearGradient, matchLinearOrientation) || matchGradient('repeating-linear-gradient', tokens.repeatingLinearGradient, matchLinearOrientation) || matchGradient('radial-gradient', tokens.radialGradient, matchListRadialOrientations) || matchGradient('repeating-radial-gradient', tokens.repeatingRadialGradient, matchListRadialOrientations);
  } // Matches a list of gradient definitions


  function matchListDefinitions() {
    return matchListing(matchDefinition);
  } // Parses the input and returns the Abstract Syntax Tree


  function getAST() {
    var _ast0$colorStops;

    const ast = matchListDefinitions();

    if (input.length > 0) {
      error('Invalid input not EOF');
    }

    const ast0 = ast[0];
    const checkSelected = ast0 === null || ast0 === void 0 ? void 0 : (_ast0$colorStops = ast0.colorStops) === null || _ast0$colorStops === void 0 ? void 0 : _ast0$colorStops.filter(c => isUpperCase(c.value)).length;

    const getGradientObj = () => {
      if (checkSelected > 0) {
        return ast0;
      } else {
        const val = (c, i) => i === 0 ? high(c) : low(c);

        return _objectSpread(_objectSpread({}, ast0), {}, {
          colorStops: ast0.colorStops.map((c, i) => _objectSpread(_objectSpread({}, c), {}, {
            value: val(c, i)
          }))
        });
      }
    };

    return getGradientObj();
  } // Matches a side or corner for gradient direction


  function matchSideOrCorner() {
    return match('directional', tokens.sideOrCorner, 1);
  } // Matches an angle value


  function matchAngle() {
    return match('angular', tokens.angleValue, 1);
  } // Matches a list of radial orientations


  function matchListRadialOrientations() {
    let radialOrientations;
    let radialOrientation = matchRadialOrientation();
    let lookaheadCache;

    if (radialOrientation) {
      radialOrientations = [];
      radialOrientations.push(radialOrientation);
      lookaheadCache = input;

      if (scan(tokens.comma)) {
        radialOrientation = matchRadialOrientation();

        if (radialOrientation) {
          radialOrientations.push(radialOrientation);
        } else {
          input = lookaheadCache;
        }
      }
    }

    return radialOrientations;
  } // Matches a radial orientation


  function matchRadialOrientation() {
    let radialType = matchCircle() || matchEllipse();

    if (radialType) {
      // @ts-expect-error - need to circle back for these types
      radialType.at = matchAtPosition();
    } else {
      const extent = matchExtentKeyword();

      if (extent) {
        radialType = extent;
        const positionAt = matchAtPosition();

        if (positionAt) {
          // @ts-expect-error - need to circle back for these types
          radialType.at = positionAt;
        }
      } else {
        const defaultPosition = matchPositioning();

        if (defaultPosition) {
          radialType = {
            type: 'default-radial',
            // @ts-expect-error - need to circle back for these types
            at: defaultPosition
          };
        }
      }
    }

    return radialType;
  } // Matches a length value


  function matchLength() {
    return match('px', tokens.pixelValue, 1) || match('em', tokens.emValue, 1);
  }

  function matchCircle() {
    const circle = match('shape', /^(circle)/i, 0);

    if (circle) {
      // @ts-expect-error - need to circle back for these types
      circle.style = matchLength() || matchExtentKeyword();
    }

    return circle;
  } // Matches an ellipse shape


  function matchEllipse() {
    const ellipse = match('shape', /^(ellipse)/i, 0);

    if (ellipse) {
      // @ts-expect-error - need to circle back for these types
      ellipse.style = matchDistance() || matchExtentKeyword();
    }

    return ellipse;
  } // Matches an extent keyword


  function matchExtentKeyword() {
    return match('extent-keyword', tokens.extentKeywords, 1);
  } // Matches the position after 'at' keyword


  function matchAtPosition() {
    if (match('position', /^at/, 0)) {
      const positioning = matchPositioning();

      if (!positioning) {
        error('Missing positioning value');
        return;
      }

      return positioning;
    }
  } // Matches positioning values


  function matchPositioning() {
    const location = matchCoordinates();

    if (location.x || location.y) {
      return {
        type: 'position',
        value: location
      };
    }
  } // Matches coordinate values


  function matchCoordinates() {
    return {
      x: matchDistance(),
      y: matchDistance()
    };
  } // Matches a number value


  function matchNumber() {
    return scan(tokens.number)[1];
  } // Converts and formats RGB values


  const convertRgb = val => {
    const capIt = isUpperCase(val === null || val === void 0 ? void 0 : val[0]);
    const captures = scan(tokens.spacedRgbColor);
    const [, r, g, b, a = 1] = captures || [null, ...matchListing(matchNumber)];
    return {
      value: `${capIt ? 'RGBA' : 'rgba'}(${r}, ${g}, ${b}, ${a})`
    };
  }; // Matches a distance value


  function matchDistance() {
    return match('%', tokens.percentageValue, 1) || matchPositionKeyword() || matchLength();
  } // Matches a position keyword


  function matchPositionKeyword() {
    return match('position-keyword', tokens.positionKeywords, 1);
  }

  return getAST();
}; // Converts the color value to lowercase


export const low = color => {
  return color.value.toLowerCase();
}; // Converts the color value to uppercase

export const high = color => {
  return color.value.toUpperCase();
}; // Create a fade css gradient from a color

export const fadeColor = color => {
  return `linear-gradient(180deg, ${transparentize(color, 1)} 0%, ${transparentize(color, 0)} 100%)`;
};