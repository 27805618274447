const DEFAULT_FRAME_PERCENTAGE = 0.15; // Is categorical strategy or is multi-report
// which is automatically categorical

export function isCategorical(selectionsStrategy, results) {
  return selectionsStrategy === 'categories' || results.length > 1;
} // Calculate the suggested chart framing
// based on the data boundaries

export function getSuggestedChartFraming(config, dataBoundaries) {
  let {
    xMin,
    xMax,
    yMin,
    yMax
  } = dataBoundaries; // Handle cases where min and max are equal to prevent zero range

  if (xMin === xMax) {
    const xPadding = xMin !== 0 ? Math.abs(xMin * 0.1) : 1;
    xMin -= xPadding;
    xMax += xPadding;
  }

  if (yMin === yMax) {
    const yPadding = yMin !== 0 ? Math.abs(yMin * 0.1) : 1;
    yMin -= yPadding;
    yMax += yPadding;
  }

  const xRange = xMax - xMin;
  const yRange = yMax - yMin;
  const xScaleMinFramePercentage = config.xScaleMinFramePercentage ?? 0;
  const xScaleMaxFramePercentage = config.xScaleMaxFramePercentage ?? DEFAULT_FRAME_PERCENTAGE;
  const yScaleMinFramePercentage = config.yScaleMinFramePercentage ?? 0;
  const yScaleMaxFramePercentage = config.yScaleMaxFramePercentage ?? DEFAULT_FRAME_PERCENTAGE;
  return {
    xMin: xMin - xRange * xScaleMinFramePercentage,
    xMax: xMax + xRange * xScaleMaxFramePercentage,
    yMin: yMin - yRange * yScaleMinFramePercentage,
    yMax: yMax + yRange * yScaleMaxFramePercentage
  };
} // Calculate total bubble radius

export function sumBubbleRadius(data) {
  return data.datasets.reduce((acc, dataset) => {
    const dataTotal = dataset.data.reduce((acc2, dataPoint) => {
      return acc2 + (dataPoint.r || 0);
    }, 0);
    return acc + dataTotal;
  }, 0);
} // Scale radius proportionally to a total

export function scaleRadius(_ref) {
  let {
    value,
    total,
    min = 2,
    max = 100
  } = _ref;
  const proportionalValue = value / total * 100;
  return Math.max(Math.min(proportionalValue, max), min);
}