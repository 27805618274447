import flattenDeep from 'lodash/flattenDeep';
import { useMemo } from 'react';
import templateEngine from '@/utils/templateEngine';
import { isValidNumber } from '@/utils/numbers';
import { getDefaultRanges, MAX_RANGE_LIMIT, MIN_RANGE_LIMIT } from './utils';
const defaultChartData = Object.freeze({
  value: 0,
  datasets: [],
  ticks: []
});
export default function useData(_ref) {
  let {
    results,
    seriesOptions,
    colorPallet,
    globalSeriesOptions = {},
    ranges = getDefaultRanges()
  } = _ref;
  const chartData = useMemo(() => {
    var _datasets;

    const flattenedResults = flattenDeep(results);
    const [resultItem] = flattenedResults;
    const dataItem = ((resultItem === null || resultItem === void 0 ? void 0 : resultItem.data) || [])[0];
    const selectedValue = dataItem === null || dataItem === void 0 ? void 0 : dataItem.value;
    const selectedLabel = dataItem === null || dataItem === void 0 ? void 0 : dataItem.label;

    if (!isValidNumber(selectedValue)) {
      return defaultChartData;
    } // Expect the selected value to be between 0 and 1
    // Multiply by 100 to get a percentage


    const value = Math.min(Math.max(selectedValue * 100, MIN_RANGE_LIMIT), MAX_RANGE_LIMIT); // Look up the first series option

    const seriesOption = Object.assign({}, globalSeriesOptions, seriesOptions[0] || {}); // Create datasets based on the ranges

    const datasets = ranges.map((range, rangeIndex) => {
      return {
        limit: range.limit,
        color: range.color || colorPallet[rangeIndex],
        label: range.label || ''
      };
    }); // Append a new range if the last range is less than the maximum

    if (((_datasets = datasets[datasets.length - 1]) === null || _datasets === void 0 ? void 0 : _datasets.limit) < MAX_RANGE_LIMIT) {
      datasets.push({
        limit: MAX_RANGE_LIMIT,
        color: colorPallet[datasets.length]
      });
    }

    return {
      value: value,
      valueTemplate: seriesOption.template ? templateEngine(seriesOption.template, {
        value: value
      }) : undefined,
      label: selectedLabel,
      ticks: datasets.map(dataset => ({
        value: dataset.limit,
        label: dataset.label || ''
      })),
      datasets: datasets
    };
  }, [results, seriesOptions, globalSeriesOptions, colorPallet, ranges]);
  return {
    chartData
  };
}