import { useMemo, useState } from 'react';
import flattenDepth from 'lodash/flattenDepth';
import createSelections from '@/utils/widget/createSelections';
import { encodeSelections } from '@/utils/widget/parseSelections';
export default function useSelections(_ref) {
  let {
    results,
    selections,
    selectionConfig,
    selectionsLabeled
  } = _ref;
  const {
    0: updated,
    1: setUpdated
  } = useState(0);
  const resultsUpdated = results.reduce((acc, result) => acc += result.updatedAt, 0);
  const selectionsHash = typeof selections === 'string' ? selections : encodeSelections(selections); // Get selection results for each report,
  // flattening them together to render as
  // separate categories

  const selectionResults = useMemo(() => {
    if (!selections) return [];
    setUpdated(Date.now());
    return flattenDepth(results.map(result => createSelections({
      result,
      selections: selections || '',
      selectionConfig,
      labeled: selectionsLabeled
    })), 1); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [`${resultsUpdated},${selectionsHash},${selectionsLabeled}`]);
  return {
    resultsUpdated,
    selectionsHash,
    selectionResults,
    selectionResultsUpdatedAt: updated
  };
}