import templateEngine from '@/utils/templateEngine';
import { spreadsheet as spreadsheetCore } from '@industriousapps/excelkits-core';
import styles from './styles.module.scss';
const {
  indexToLetters
} = spreadsheetCore;
const selectors = {
  highlightedLabel: styles.highlightLabel,
  hideTooltipState: '-hideTooltip'
};
export default function useTreeMapData(results, seriesOptions, colorPallet) {
  if (!results || !results.length) {
    return {
      data: []
    };
  }

  const colorsLen = colorPallet.length; // Add parents for treemap children

  const parentEntries = results.map((_, index) => ({
    id: indexToLetters(index)
  }));
  const data = results.map((collection, collectionIndex) => {
    const parentEntry = parentEntries[collectionIndex];
    const groupColor = colorPallet[collectionIndex % colorsLen]; // Pair selection group with series options

    return collection.map(selectionResultGroup => {
      const options = seriesOptions.find(_ref => {
        let {
          selections
        } = _ref;
        return selections === selectionResultGroup.selections;
      });
      return {
        group: selectionResultGroup,
        options
      };
    }) // Create series data
    .map(_ref2 => {
      let {
        group,
        options = {}
      } = _ref2;
      const customLabels = options.labels || {}; // Provide default template

      const template = options.template || `{{value}}`; // Convert each item in group
      // into a renderable item for
      // the visualization

      return group.data.filter(item => item.value > 0).map(item => {
        const customLabel = customLabels[item.labelCell || ''] || '';
        const name = customLabel || item.label || group.selections;
        return {
          name,
          value: Math.round(parseFloat(`${item.value}`)),
          selection: group.selections,
          selectionIndex: group.selectionIndex,
          // Group selections together under
          // their collection/parent
          parent: parentEntry.id,
          color: options.color || groupColor,
          compiledTemplate: templateEngine(template, {
            value: item.value
          })
        };
      });
    }) // Flatten collection's selections into single
    // list for each collection of selections
    .reduce((acc, groupedData) => {
      acc.push(...groupedData);
      return acc;
    }, []);
  }) // Flatten each collection into single list
  .reduce((acc, collectionGroups) => {
    acc.push(...collectionGroups);
    return acc;
  }, []); // Add text content into
  // each square of tree map

  data.forEach(entry => {
    // @ts-ignore
    entry.dataLabels = {
      format: null,
      selection: entry.selection,
      selectionIndex: entry.selectionIndex,
      className: `${selectors.highlightedLabel} -largest`,
      useHTML: true,
      formatter: function () {
        return `<strong>${this.point.compiledTemplate}</strong><p>${this.point.name}</p>`;
      }
    };
  });
  return {
    data: [...parentEntries, ...data]
  };
}