import { useEffect, useState } from 'react';
import throttle from '@/utils/throttle';
export default function useContainerSize(containerRef) {
  const {
    0: containerSize,
    1: setContainerSize
  } = useState({
    width: 0,
    height: 0
  });
  const containerObserver = typeof ResizeObserver === 'undefined' ? createresizeObserverStub() : new ResizeObserver(throttle(entries => {
    for (const entry of entries) {
      const [containerSize] = entry.contentBoxSize;
      setContainerSize({
        width: containerSize && containerSize.inlineSize || 0,
        height: containerSize && containerSize.blockSize || 0
      });
    }
  }, 60, {
    leading: false,
    trailing: true
  })); // eslint-disable-next-line

  useEffect((() => {
    let currentRef;
    return () => {
      if (!containerRef.current) return;
      if (containerRef.current instanceof HTMLElement === false) return;
      if (!currentRef) currentRef = containerRef.current;
      containerObserver.observe(currentRef);
      return () => containerObserver.unobserve(currentRef);
    };
  })(), [containerRef.current]);
  return containerSize;
}

function createresizeObserverStub() {
  return {
    observe: () => undefined,
    unobserve: () => undefined
  };
}