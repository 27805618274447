import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import regression from 'regression';
import { TrendlineAlgorithm } from '@/interfaces/reporting/trendline';
import { SMOOTH_TENSION, SHARP_TENSION } from '@/components/widget/utils/tension';
import universalDesignSystem from '@/config/colorPallets/universalDesignSystem';
export const DEFAULTS = {
  borderWidth: 1,
  borderColor: universalDesignSystem.gray20
}; // Create an index to store data
// for a trendline configuration

export const createTrendlineMap = () => new Map(); // Append data to a
// trendline configuration

export function appendTrendlineData(configs, options, data) {
  if (!configs.has(options)) {
    configs.set(options, []);
  }

  (configs.get(options) || []).push(...data);
} // Generate Chartjs line dataset to serve as
// the trendline for the given data

export function createTrendlineDataset(trendlineOptions, trendlineData, minX, maxX) {
  const algorithm = trendlineOptions.algorithm || TrendlineAlgorithm.Linear;
  const regessionData = trendlineData.map(_ref => {
    let {
      x,
      y
    } = _ref;
    return [x, y];
  });
  if (regessionData.length === 0) return null; // Generate points by algorithm, defaulting to linear

  let result;

  try {
    if (algorithm === TrendlineAlgorithm.Exponential) {
      result = regression.exponential(regessionData);
    } else if (algorithm === TrendlineAlgorithm.Logarithmic) {
      result = regression.logarithmic(regessionData);
    } else if (algorithm === TrendlineAlgorithm.Power) {
      result = regression.power(regessionData);
    } else if (algorithm === TrendlineAlgorithm.Polynomial) {
      result = regression.polynomial(regessionData);
    } else {
      result = regression.linear(regessionData);
    } // eslint-disable-next-line no-empty

  } catch (err) {} // Avoid invalid results


  if (!result || result.points.length === 0) return null;
  let minPoint = [];
  let maxPoint = [];
  const points = [...result.points].sort((a, b) => a[0] - b[0]);

  try {
    minPoint = result.predict(minX);
    maxPoint = result.predict(maxX); // eslint-disable-next-line no-empty
  } catch (err) {} // Add any relevant predicted minimum X point


  if (minPoint.length && minPoint[0] !== points[0][0]) {
    points.unshift(minPoint);
  } // Add any relevant predicted maximum X point


  if (maxPoint.length && maxPoint[0] !== points[points.length - 1][0]) {
    points.push(maxPoint);
  } // Ensure that legend background color matches line color


  if (!trendlineOptions.backgroundColor && trendlineOptions.borderColor) {
    trendlineOptions.backgroundColor = trendlineOptions.borderColor;
  } // Create Chart.js line dataset


  return _objectSpread(_objectSpread(_objectSpread({}, _objectSpread(_objectSpread({}, DEFAULTS), {}, {
    tension: algorithm === TrendlineAlgorithm.Linear ? SHARP_TENSION : SMOOTH_TENSION,
    fill: false // Hide background when set for legend

  })), trendlineOptions), {
    type: 'line',
    pointRadius: 0,
    // Hides data points along line
    data: points.map(_ref2 => {
      let [x, y] = _ref2;
      return {
        x,
        y
      };
    })
  });
}