import { useState } from 'react';
import globalEvents from '@/utils/globalEvents';
import { customFormValidityMethod } from '@/config/general'; // const PREFIX = 'common: components: reporting: Form: useValidation:';

export default function useValidation(questions, formElement) {
  const {
    0: invalidElements,
    1: setInvalidElements
  } = useState([]);
  const {
    0: isValid,
    1: setIsValid
  } = useState(true); // Send global event to notify
  // question subscribers of changes
  // to their validation state
  // NOTE: if target provide only that
  // question will be validated

  const broadcastValidationChanges = targetQuestionId => {
    if (!formElement || !formElement.elements) {
      return [];
    }

    const latestInvalidElements = questions.filter(question => targetQuestionId ? question.id === targetQuestionId : true).map(question => formElement.elements.namedItem(question.id)).filter(el => {
      let formField = el; // Use first field in node list
      // to check validity, they should
      // all return the same result
      // NOTE: checks are fix for jest

      if (window && typeof window.RadioNodeList !== 'undefined' && el instanceof window.RadioNodeList) {
        formField = el.item(0);
      } // Check validity


      if (formField) {
        // @ts-ignore
        const isAnswerValid = typeof formField[customFormValidityMethod] === 'function' ? // @ts-ignore
        formField[customFormValidityMethod]() // use custom validation
        : // @ts-ignore
        formField.checkValidity(); // TODO check if input has validation rules to ignore

        return isAnswerValid === false;
      }

      return false;
    });
    const latestInvalidElementIds = latestInvalidElements.map(el => el.id);
    const newlyValidElements = invalidElements.filter(el => latestInvalidElementIds.includes(el.id) === false); // Broadcast invalids

    latestInvalidElements // Avoid rebroadcasting invalids
    .filter(el => invalidElements.includes(el) === false).map(el => {
      globalEvents.trigger('questioninvalid', {
        detail: el.id // question ID

      });
    }); // Broadcast valids

    newlyValidElements.map(el => {
      globalEvents.trigger('questionvalid', {
        detail: el.id
      });
    });
    setInvalidElements([...latestInvalidElements]);
    setIsValid(latestInvalidElements.length === 0);
    return latestInvalidElements;
  };

  return {
    broadcastValidationChanges,
    invalidElements,
    isValid
  };
}