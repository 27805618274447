import * as React from 'react';

// Set a given ref to a given value
// This utility takes care of different types of refs: callback refs and RefObject(s)
function setRef(ref, value) {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref !== null && ref !== undefined) {
    ref.current = value;
  }
} // A utility to compose multiple refs together
// Accepts callback refs and RefObject(s)


function composeRefs() {
  for (var _len = arguments.length, refs = new Array(_len), _key = 0; _key < _len; _key++) {
    refs[_key] = arguments[_key];
  }

  return node => refs.forEach(ref => setRef(ref, node));
} // A custom hook that composes multiple refs
// Accepts callback refs and RefObject(s)


export default function useComposedRefs() {
  for (var _len2 = arguments.length, refs = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    refs[_key2] = arguments[_key2];
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useCallback(composeRefs(...refs), refs);
}