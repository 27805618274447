import { useCallback } from 'react';
import { chartColorPallet as DEFAULT_COLORS } from '@/config/colorPallets/universalDesignSystem';
import templateEngine from '@/utils/templateEngine';
import { getColorAtPalletIndex } from '@/utils/colors';
export default function useChartActions(_ref) {
  let {
    legendsColorPallet = DEFAULT_COLORS,
    collectionOptions = [],
    valueAxisTemplate
  } = _ref;
  // Toggle visibility of column
  const toggleLegendVisibility = useCallback((_e, legendItem, legend) => {
    const ci = legend.chart;
    ci.toggleDataVisibility(legendItem.index);
    ci.update();
  }, []); // Generate unique legend labels with dataset indexes
  // for toggling visibility

  const generateLegendLabels = useCallback((chart, legends) => {
    return legends.map((legend, index) => {
      const collectionOption = collectionOptions[index] || {};
      return {
        text: legend,
        hidden: !chart.getDataVisibility(index),
        fillStyle: collectionOption.color || getColorAtPalletIndex(index, legendsColorPallet),
        index
      };
    });
  }, [legendsColorPallet, collectionOptions]); // Custom tick callback for value axis

  const valueAxisTicksCallback = useCallback(value => {
    if (!valueAxisTemplate) return value;
    return templateEngine(valueAxisTemplate, {
      value: value
    });
  }, [valueAxisTemplate]);
  return {
    toggleLegendVisibility,
    generateLegendLabels,
    valueAxisTicksCallback
  };
}