import copy from '@/utils/copyTextToClipboard';
import { toUrlValue } from '@/utils/reporting/parseUserAnswer';
import reduceAnswers from './utils/reduceAnswers'; // const PREFIX = 'common: components: reporting: Form: useSaveChange:';

const EXCLUDED_LAYOUTS = ['hidden'];
export default function useSharing(questions, elementId) {
  const saveShareLinkToClipboard = async formElements => {
    // Strip out the hash and query parameter
    const pageUrl = (true ? window.location.href : '').split('#')[0].split('?')[0];
    const userAnswers = reduceAnswers({
      questions,
      formElements,
      reducer: (acc, question, userAnswer) => {
        const queryParam = `${question.name || question.id}`; // Add if question's layout is not excluded
        // from appending to share links

        if (!EXCLUDED_LAYOUTS.includes(question.layout)) {
          acc[queryParam] = toUrlValue(question, userAnswer);
        }

        return acc;
      }
    }); // Convert user answers to hash

    const params = Object.entries(userAnswers).reduce((acc, _ref) => {
      let [param, value] = _ref;
      return acc += `${encodeURIComponent(param)}=${encodeURIComponent(value)}&`;
    }, '').replace(/&$/, ''); // Copy to user's clipboard

    const shareLink = `${pageUrl}${params ? '?' + params : ''}${elementId ? '#' + elementId : ''}`;

    try {
      await copy(shareLink); // eslint-disable-next-line
    } catch (err) {
      return ''; // failure
    }

    return shareLink;
  };

  return {
    saveShareLinkToClipboard
  };
}