import { cloneDeep } from 'lodash';
import designConfig from '@/config/design';
const DEFAULT_CONFIG = {
  paddingPercentage: 5,
  display: false,
  labels: []
};
export default function usCenterLabels(_ref) {
  let {
    centerLabel,
    centerValue
  } = _ref;
  const config = cloneDeep(DEFAULT_CONFIG);
  config.display = Boolean(centerLabel || centerValue);
  if (centerLabel) config.labels.push(createLabelText(centerLabel));
  if (centerValue) config.labels.push(createValueText(centerValue));
  return {
    config,
    redrawHash: [config.display, centerLabel, centerValue].filter(Boolean).join('.')
  };
}

function createLabelText(value) {
  return {
    text: (() => {
      let render = 0; // Fixes bug where center text
      // renders once for each dataset

      return ctx => {
        const text = render % ctx.data.datasets.length === 0 ? value : '';
        render++;
        return text;
      };
    })(),
    font: {
      size: '18',
      family: designConfig.fontFamily,
      style: 'normal',
      weight: 'normal'
    },
    color: designConfig.colors.gray60
  };
}

function createValueText(value) {
  return {
    text: (() => {
      let render = 0; // Fixes bug where center text
      // renders once for each dataset

      return ctx => {
        const text = render % ctx.data.datasets.length === 0 ? value : '';
        render++;
        return text;
      };
    })(),
    font: {
      size: '40',
      family: designConfig.fontFamily,
      style: 'normal',
      weight: 'normal'
    },
    color: designConfig.colors.gray100
  };
}