// Extract the users' value
// depending on the type of the field
export default function getFormValue(field) {
  const fieldType = field.getAttribute('type');

  if (fieldType === 'checkbox') {
    // @ts-ignored
    return field.checked;
  } // @ts-ignored


  return field.value;
}