const winSearch = true ? window.location.search : '';
const urlParams = new URLSearchParams(winSearch);
export default new Proxy(urlParams, {
  // Return decoded query string param
  get: (_, prop) => {
    return decodeURIComponent(urlParams.has(prop) ? urlParams.get(prop) || '' : '');
  },
  // Encode and set Query String param
  // and broadcast Custom Event
  set: (_, prop, value) => {
    // Delete property
    if (!value) {
      urlParams.delete(prop); // remove from URL

      pushLatestHistoryState(); // broadcasts change

      return true; // must be truthey
    }

    const update = encodeURIComponent(value);

    if (urlParams.has(prop)) {
      urlParams.set(prop, update);
    } else {
      urlParams.append(prop, update);
    }

    pushLatestHistoryState();
    return Boolean(decodeURIComponent(urlParams.get(prop) || ''));
  }
});
export const utils = {
  getStateHash,
  getStateString
}; // Get decoded state of all query params

function getStateHash() {
  // @ts-ignore
  const enteries = urlParams.entries();
  const data = Object.create(null);

  for (const entry of enteries) {
    data[entry[0]] = decodeURIComponent(entry[1]);
  }

  return data;
} // Get encoded state of all query params


function getStateString() {
  return urlParams.toString();
} // Append a new state to the history
// for updated query params
// TODO move to global events


function pushLatestHistoryState() {
  const qs = urlParams.toString();
  const data = getStateHash();
  history.pushState(data, '', decodeURIComponent(`${window.location.pathname}${qs ? '?' : ''}${qs}`));
  const event = new CustomEvent('queryparamchange', {
    detail: data
  });
  window.dispatchEvent(event);
}