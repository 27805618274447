export default {
  styles: {
    gridMaxColumns: '1',
    paddingTop: '0',
    paddingRight: '0',
    paddingBottom: '0',
    paddingLeft: '0'
  },
  stylesMediumUp: {},
  stylesMedium: {},
  stylesLarge: {},
  stylesMainSections: {},
  stylesMainSectionsMediumUp: {}
};