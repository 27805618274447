import { marked } from 'marked';
import templateEngine from '@/utils/templateEngine';
import { getFlattenedIndexOf } from '@/utils/array';
import { getColumnCount, groupSelectionResultsByIndex } from '../utils'; // Create columns by treating the
// first selected row as headers
// unless explicity set to `strong: false`

export function createColumns(srcResults, seriesOptions) {
  const results = srcResults[0] || [];
  const groupedResults = groupSelectionResultsByIndex(results);
  const firstRow = getFirstRow(groupedResults);
  const columns = getColumnCount([groupedResults]);
  const options = seriesOptions[getFlattenedIndexOf(srcResults, firstRow)] || {
    selections: firstRow.selections
  };
  const isHeader = hasHeader(options);
  return Array(columns).fill(0).map((_, index) => ({
    Header: isHeader ? `${(firstRow.data[index] || {
      value: ''
    }).value}` || '' : '',
    accessor: `col${index}`,
    // accessor is the "key" in the data
    selection: firstRow.selections,
    selectionIndex: firstRow.selectionIndex || 0
  }));
} // Create table data from non-header selections

export function createData(srcResults, seriesOptions) {
  // Rows only support single collection
  const results = srcResults[0] || [];
  const groupedResults = groupSelectionResultsByIndex(results);
  const firstRow = getFirstRow(groupedResults);
  const columns = getColumnCount([groupedResults]); // Lookup or create series options

  const firstRowOptions = seriesOptions[getFlattenedIndexOf(srcResults, firstRow)] || {
    selections: firstRow.selections
  };
  const hasFirstRowHeader = hasHeader(firstRowOptions);
  const rows = groupedResults.slice(hasFirstRowHeader ? 1 : 0);
  return rows.map(row => {
    const options = seriesOptions[getFlattenedIndexOf(srcResults, row)] || {
      selections: row.selections
    }; // Provide default template

    const template = options.template || `{{value}}`;
    return Array(columns).fill(0).reduce((acc, _, colIndex) => {
      const column = row.data[colIndex] || {
        value: ''
      };
      const srcValue = column.value;
      const value = srcValue !== undefined ? srcValue : ''; // Interpolate the template

      acc[`col${colIndex}`] = marked.parseInline(templateEngine(template, {
        value
      }));
      acc[`colSelection${colIndex}`] = row.selections || '';
      acc[`colSelectionIndex${colIndex}`] = row.selectionIndex || 0;
      return acc;
    }, {});
  });
} // True by default, user must explicitly set
// to false to remove this selection from header

function hasHeader(seriesOptions) {
  return seriesOptions.strong === false ? false : true;
} // Lookup first row in 1st selection
// result group or return empty result


function getFirstRow(groupedResults) {
  const firstSelection = groupedResults[0]; // Discover selection with most rows

  return ((firstSelection || {}).data || []).length ? firstSelection : {
    selections: '',
    data: [],
    selection: '',
    selectionIndex: -1,
    collectionIndex: -1
  };
}