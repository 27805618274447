import { useMemo } from 'react';
import { ordinalSectionsToArray } from '@/utils/widget/ordinals'; // Create an array of sections
// ordered according to their ordinal names

function useOrdinalSections(sections) {
  const orderedSections = useMemo(() => {
    return ordinalSectionsToArray(sections);
  }, [sections]);
  return orderedSections;
}

export default useOrdinalSections;