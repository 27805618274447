import get from 'lodash/get';
import { useMemo } from 'react';
import { marked } from 'marked';
import flatten from 'lodash/flatten';
import templateEngine from '@/utils/templateEngine';
import { selectionResultsToHash, seriesOptionsToHash } from '@/utils/widget/hashing';
export default function useStackedBarsData(config) {
  const {
    results,
    seriesOptions,
    selectionsStrategy
  } = config;
  const hasRenderableResults = results && results.length >= 1 && results.some(result => result.every(report => report && report.data && Boolean(report.data.length)));
  const resultsHash = selectionResultsToHash(results);
  const optionsHash = seriesOptionsToHash(seriesOptions);
  const {
    data,
    maximum
  } = useMemo(() => {
    if (!hasRenderableResults) {
      return {
        data: [],
        maximum: 0
      };
    } // Create rows from each selection
    // or pair each collections' selections
    // together for reporting strategy


    const data = (selectionsStrategy === 'groups' ? collectionsToRows : selectionsToRows)(results, seriesOptions); // Find maximum of all left/right values

    const maximum = data === null || data === void 0 ? void 0 : data.reduce((acc, _ref) => {
      let {
        leftAmount,
        rightAmount
      } = _ref;
      return Math.max(leftAmount || 0, rightAmount || 0, acc);
    }, 0);
    return {
      data,
      maximum
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRenderableResults, resultsHash, optionsHash, selectionsStrategy]);
  return {
    data,
    maximum
  };
} // Flatten collections together to
// generate a single list of rows

export function selectionsToRows(results, seriesOptions) {
  return flatten(results) // Pair selection group with series options
  .map(selectionResultGroup => {
    const options = seriesOptions[selectionResultGroup.selectionIndex] || {
      selections: selectionResultGroup.selections
    }; // empty options

    return {
      group: selectionResultGroup,
      options
    };
  }) // Filter collapsed
  .filter(_ref2 => {
    let {
      group,
      options = {}
    } = _ref2;
    // Collapsible by default
    const collapsible = typeof options.collapsible === 'boolean' ? options.collapsible : true; // Filter out collapsed group
    // when all its' values are not truthy

    if (collapsible) {
      return group.data.some(item => Boolean(item.value));
    }

    return true;
  }) // Create series data
  .map(_ref3 => {
    let {
      group,
      options = {}
    } = _ref3;
    // Provide default template
    const template = options.template || `{{value}}`; // Convert each item in group
    // into a renderable item for
    // the visualization

    return {
      selections: group.selections,
      label: options.label || get(group, 'data[0].label', group.selections),
      leftAmount: get(group, 'data[0].value', 0),
      leftText: marked.parseInline(templateEngine(template, {
        value: get(group, 'data[0].value', 0)
      })),
      selectionLeft: group.selections,
      selectionLeftIndex: group.selectionIndex,
      rightAmount: get(group, 'data[1].value', 0),
      rightText: marked.parseInline(templateEngine(template, {
        value: get(group, 'data[1].value', 0)
      })),
      selectionRight: group.selections,
      selectionRightIndex: group.selectionIndex,
      tooltip: options.tooltip || ''
    };
  });
} // Convert first 2 selections of each collection
// into pairs that compare values left/right

export function collectionsToRows(results, seriesOptions) {
  return results.map(_ref4 => {
    let [left = {}, right = {}] = _ref4;
    const selectionLeftIndex = left.selectionIndex;
    const selectionRightIndex = right.selectionIndex;
    const optionsLeft = seriesOptions[selectionLeftIndex] || {
      selections: left.selections || 'NA'
    }; // empty options

    const optionsRight = seriesOptions[selectionRightIndex] || {
      selections: right.selections || 'NA'
    }; // empty options

    return {
      left,
      selectionLeft: left.selections,
      selectionLeftIndex,
      optionsLeft,
      right,
      selectionRight: right.selections,
      selectionRightIndex,
      optionsRight
    };
  }) // Filter collapsed
  .filter(_ref5 => {
    let {
      left,
      optionsLeft = {},
      right
    } = _ref5;
    // Collapsible by default
    const collapsibleLeft = typeof optionsLeft.collapsible === 'boolean' ? optionsLeft.collapsible : true; // Filter out collapsed group
    // when all its' values are not truthy

    if (collapsibleLeft) {
      return [...get(left, 'data', []), ...get(right, 'data', [])].some(item => Boolean(item.value));
    }

    return true;
  }) // Create series data
  .map(_ref6 => {
    let {
      left,
      selectionLeft,
      selectionLeftIndex,
      optionsLeft = {},
      right,
      selectionRight,
      selectionRightIndex,
      optionsRight = {}
    } = _ref6;
    // Provide default template
    const templateLeft = optionsLeft.template || `{{value}}`;
    const templateRight = optionsRight.template || `{{value}}`; // Descriptive selections text

    const selections = `${left.selections || ''} vs ${right.selections || 'Missing'}`; // Convert each item in group
    // into a renderable item for
    // the visualization

    return {
      selections,
      label: // 1st series options, 2nd left option label, 3rd right option label
      optionsLeft.label || get(left, 'data[0].label', get(right, 'data[0].label', selections)),
      leftAmount: get(left, 'data[0].value', 0),
      leftText: marked.parseInline(templateEngine(templateLeft, {
        value: get(left, 'data[0].value', 0)
      })),
      selectionLeft: selectionLeft,
      selectionLeftIndex: selectionLeftIndex,
      rightAmount: get(right, 'data[0].value', 0),
      rightText: marked.parseInline(templateEngine(templateRight, {
        value: get(right, 'data[0].value', 0)
      })),
      selectionRight: selectionRight,
      selectionRightIndex: selectionRightIndex,
      tooltip: optionsLeft.tooltip || ''
    };
  });
}