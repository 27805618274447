import design, { _remCalc } from '@/config/design';
export default {
  styles: {
    // Widget container defaults
    widgetHeight: 'auto',
    widgetMaxWidth: _remCalc(design.widgetMaxWidth),
    widgetMaxHeight: 'none',
    widgetPaddingTop: design.globals.lgPadding,
    widgetPaddingRight: design.globals.lgPadding,
    widgetPaddingBottom: design.globals.lgPadding,
    widgetPaddingLeft: design.globals.lgPadding,
    widgetBackgroundColor: design.colors.white,
    // Section group grid defaults
    gridCellMaxHeight: 'auto',
    gridRowGap: design.globals.xlgPadding,
    gridColumnGap: design.globals.lgPadding,
    // Section grid/item defaults
    borderTopWidth: '0',
    borderRightWidth: '0',
    borderBottomWidth: '0',
    borderLeftWidth: '0',
    borderTopStyle: 'solid',
    borderRightStyle: 'solid',
    borderBottomStyle: 'solid',
    borderLeftStyle: 'solid',
    borderTopColor: design.colors.gray20,
    borderRightColor: design.colors.gray20,
    borderBottomColor: design.colors.gray20,
    borderLeftColor: design.colors.gray20,
    // Misc settings
    sectionHeaderHeight: design.globals.xxlgPadding
  },
  stylesTheme: {
    remBase: `${design.remBase}`,
    globalXsmPadding: _remCalc(design.globalPaddings.xsmPadding),
    globalSmPadding: _remCalc(design.globalPaddings.smPadding),
    globalMdPadding: _remCalc(design.globalPaddings.mdPadding),
    globalPadding: _remCalc(design.globalPaddings.padding),
    globalLgPadding: _remCalc(design.globalPaddings.lgPadding),
    globalXlgPadding: _remCalc(design.globalPaddings.xlgPadding),
    globalXxlgPadding: _remCalc(design.globalPaddings.xxlgPadding),
    globalXxxlgPadding: _remCalc(design.globalPaddings.xxxlgPadding),
    globalXxxxlgPadding: _remCalc(design.globalPaddings.xxxxlgPadding),
    globalXxxxxlgPadding: _remCalc(design.globalPaddings.xxxxxlgPadding)
  },
  stylesMediumUp: {}
}; // Optional sectional groups for layouts
// NOTE: Should be sorted alphabetically

export const SECTION_GROUPS = {
  summary: ['sidebar'],
  sidebarClothesline: ['sidebar']
}; // Baseline values for sections

export const baselineLayoutStyles = {
  // Baseline chart height, used instead
  // grid cell max height, when its set to auto
  // configured by <WidgetStyleVariables>
  chartAutoHeight: _remCalc(522)
};