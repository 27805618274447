export const GENERIC_BOOLEAN_OPTIONS = encodeSelectOptions([{
  value: 'true',
  content: 'True'
}, {
  value: 'false',
  content: 'False'
}]); // Convert a string of formatted question options
// into a list of select menu options

export default function parseOptions() {
  let optionsString = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  const rows = optionsString.split(',');
  return rows.map(option => {
    const [valueSrc, contentSrc] = option.split('|');
    const value = decodeURIComponent(valueSrc.trim());
    const content = contentSrc ? decodeURIComponent(contentSrc.trim()) : '';
    return {
      value,
      content: content || value // Reuse value when content is non-existent

    };
  }).filter(_ref => {
    let {
      value,
      content
    } = _ref;
    return Boolean(value) || Boolean(content);
  });
} // Convert list of options into an
// encoded options string

export function encodeSelectOptions(options) {
  return options // Remove incomplete
  .filter(_ref2 => {
    let {
      content,
      value
    } = _ref2;
    return Boolean(`${content.trim()}`) && Boolean(`${value.trim()}`);
  }) // Concatinate into `{{value}} | {{content}},...`
  .reduce((acc, option) => {
    return acc += `${encodeURIComponent(option.value.trim())} | ${encodeURIComponent(option.content.trim())},`;
  }, '').replace(/,$/, '');
}