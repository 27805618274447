import uniq from 'lodash/uniq';
// Count columns in widest selection
// in 1st collection within results
export const getColumnCount = srcResults => {
  const results = srcResults[0] || [];
  return Math.max(0, results.reduce((acc, _ref) => {
    let {
      data
    } = _ref;
    return data.length > acc ? data.length : acc;
  }, 0));
}; // Combine selection groups into a single
// selection group with a longer data array

export function combineSelectionResults(results) {
  const [firstResult] = results;
  const lastResult = results[results.length - 1];
  const result = {
    selectionIndex: firstResult.selectionIndex,
    collectionIndex: firstResult.collectionIndex,
    selections: `${firstResult.selections}|${lastResult.selections}`,
    data: []
  };
  return results.reduce((acc, selectionGroup) => {
    acc.data.push(...selectionGroup.data);
    return acc;
  }, result);
} // Group together all selections by index

export function groupSelectionResultsByIndex(results) {
  const indexes = uniq(results.map(_ref2 => {
    let {
      selectionIndex
    } = _ref2;
    return selectionIndex;
  }));
  return indexes.map(index => combineSelectionResults(results.filter(_ref3 => {
    let {
      selectionIndex
    } = _ref3;
    return selectionIndex === index;
  }))).sort((a, b) => a.selectionIndex - b.selectionIndex);
}