import templateEngine from '@/utils/templateEngine';
import useSelections from '@/components/widget/misc/useSelections';
export const selectionConfig = {};
export default function useSingleSelection(_ref) {
  let {
    results,
    selection = '',
    template
  } = _ref;
  const isDataSelection = selection.search('=') === 0; // Get relevant results for each selection group

  const {
    selectionResults
  } = useSelections({
    results,
    selections: !isDataSelection ? '' : selection.replace(/^=/, ''),
    selectionConfig,
    selectionsLabeled: false
  }); // Return static value

  if (!isDataSelection) {
    return selection;
  }

  if (!selectionResults || !selectionResults.length || !selection) {
    return '';
  }

  const data = (((selectionResults[0] || [])[0] || {}).data || [])[0] || {};
  return templateEngine(template || '{{value}}', {
    value: data.value
  });
}