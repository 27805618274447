import flatten from 'lodash/flatten';
// Utility to check if selection
// has any useful selections data
export const hasAnySelectionData = selectionsGroups => selectionsGroups.reduce((acc, selections) => acc += selections.length, 0) > 0; // Utility to check if user selection
// query has any usable information

export const hasSelections = selections => {
  if (Array.isArray(selections)) {
    return flatten(selections).filter(Boolean).length > 0;
  } else {
    return Boolean(selections);
  }
};